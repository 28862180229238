import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    window.location.href = `/`;
  }, []);
  //
  const [FormState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    audit: "",
    message: "",
  });
  //

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setFormState({
      ...FormState,
      [e.target.name]: e.target.value,
    });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "General Contact", ...FormState }),
    })
      .then(() => window.open("../thank-you", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };
  console.log(FormState);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Marketing & SEO Services Dunedin, FL</title>

        <meta
          name="description"
          content="Consult FGC is headquarterd in Dunedin, FL offering digital marketing services nationwide. We offer an experienced but personal touch to help you grow your business online. "
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div class="bg-gradient-to-l from-secondaryBlue to-primaryBlue">
        <div class="py-24">
          <div class="text-center relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-extrabold tracking-tight text-white sm:text-6xl">
              Ready to Improve Your Business?
            </h1>
            <p class="mx-auto mt-6 text-xl text-white max-w-4xl">
              Consult FGC is ready to help. Fill out the form below to get in
              touch with us as well as request your Free Website Audit. Or feel
              free to email us or call with the information below:
            </p>
          </div>
        </div>
      </div>
      <section
        class="relative bg-white pb-20"
        aria-labelledby="contact-heading"
      >
        <div
          class="absolute w-full h-1/2 bg-gradient-to-l from-secondaryBlue to-primaryBlue"
          aria-hidden="true"
        ></div>

        <div class=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative bg-white shadow-xl">
            <div class="grid grid-cols-1 lg:grid-cols-3">
              <div class="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-cyan-500 to-teal-300 sm:px-10 xl:p-12">
                <div
                  class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    class="absolute inset-0 w-full h-full"
                    width="160"
                    height="678"
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="blue"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff"></stop>
                        <stop
                          offset="1"
                          stop-color="#fff"
                          stop-opacity="0"
                        ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 class="text-lg font-medium text-white">Consult FGC</h3>
                <p class="mt-6 text-base text-teal-50 max-w-3xl">
                  1659 Achieva Way, Suite 146{" "}
                  <span class="block">Dunedin, FL 34698</span>
                </p>
                <dl class="mt-8 space-y-6">
                  <dt>
                    <span class="sr-only">Phone number</span>
                  </dt>
                  <dd class="flex text-base text-teal-50">
                    <svg
                      class="flex-shrink-0 w-6 h-6 text-teal-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span class="ml-3">+1 (888) 341-7735</span>
                  </dd>
                  <dt>
                    <span class="sr-only">Email</span>
                  </dt>
                  <dd class="flex text-base text-teal-50">
                    <svg
                      class="flex-shrink-0 w-6 h-6 text-teal-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span class="ml-3">marketing@consultfgc.com</span>
                  </dd>
                </dl>
                <ul role="list" class="mt-8 flex space-x-12">
                  <li>
                    <a
                      class="text-teal-200 hover:text-teal-100"
                      href="https://www.facebook.com/ConsultFGC/"
                    >
                      <span class="sr-only">Facebook</span>
                      <svg
                        class="w-8 h-8"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-teal-200 hover:text-teal-100"
                      href="https://www.linkedin.com/company/consult-fgc/"
                    >
                      <span class="sr-only">Linkedin</span>
                      <svg
                        class="mt-0.5 w-7 h-7"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <form
                  method="POST"
                  target="_self"
                  id="General Contact"
                  name="General Contact"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                  class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <label
                      for="firstName"
                      class="block text-sm font-medium text-gray-900"
                    >
                      First name
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={handleChange}
                        autocomplete="given-name"
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="lastName"
                      class="block text-sm font-medium text-gray-900"
                    >
                      Last name
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={handleChange}
                        autocomplete="family-name"
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <div class="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        onChange={handleChange}
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="flex justify-between">
                      <label
                        for="phone"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Phone
                      </label>
                      <span id="phone-optional" class="text-sm text-gray-500">
                        Optional
                      </span>
                    </div>
                    <div class="mt-1">
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        autocomplete="tel"
                        onChange={handleChange}
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                        aria-describedby="phone-optional"
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="audit"
                      class="block text-sm font-medium text-gray-900"
                    >
                      Please audit my site -{" "}
                      <span class="text-gray-400">www.yourwebsite.com</span>
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="audit"
                        id="audit"
                        onChange={handleChange}
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="flex justify-between">
                      <label
                        for="message"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Message
                      </label>
                      <span id="message-max" class="text-sm text-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                    <div class="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        onChange={handleChange}
                        class="py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                        aria-describedby="message-max"
                      ></textarea>
                    </div>
                  </div>
                  <div class="sm:col-span-2 sm:flex sm:justify-end">
                    {SubmitDisabled === true ? (
                      <button
                        type="submit"
                        class="mt-2 w-full inline-flex items-center justify-center px-20 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none sm:w-auto"
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        class="mt-2 w-full inline-flex items-center justify-center px-20 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none sm:w-auto"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
